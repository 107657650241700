import React from "react";
import "./Signin.css";

const Signin = props => {
  return (
    <div className="SignMain">
      <div className="SignSecond">
        <h1>Signin</h1>
        <div>Email</div>
        <input
          placeholder="Email"
          type="text"
          onChange={e => props.changeEmail(e.target.value)}
        />
        <div>Password</div>
        <input
          placeholder="Password"
          type="password"
          onChange={e => props.changePassword(e.target.value)}
        />
        <button onClick={props.onSignin}>Signin</button>
        <br />
      </div>
    </div>
  );
};

export default Signin;
