import React, { useState } from "react";
import EnterValue from "./EnterValue/EnterValue";
import Signin from "./Signin/Signin";
import "./Auth.css";
import Swal from "sweetalert2";
import { firebaseApp } from "../firebase";
import Loader from "react-loader-spinner";

const Auth = props => {
  const auth = firebaseApp.auth();
  const [iEmail, changeEmail] = useState();
  const [wait, setWait] = useState(false);
  const [iPassword, changePassword] = useState();
  const signin = () => {
    setWait(true);
    auth
      .signInWithEmailAndPassword(iEmail, iPassword)
      .then(res => {
        setWait(false);
        props.history.push("/EnterValue");
      })
      .catch(err => {
        console.log(err);
        setWait(false);
        Swal.fire({
          title: "Error!",
          text: "Wrong Email or Password",
          icon: "error",
          confirmButtonText: "OK"
        });
      });
  };
  if (wait) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh"
        }}
      >
        <Loader type="Oval" color="#18207e" />
      </div>
    );
  } else
    return (
      <>
        <div className="AuthMain">
          <div className="AuthSecond">
            <Signin
              changePassword={changePassword}
              changeEmail={changeEmail}
              onSignin={signin}
            />
          </div>
        </div>
      </>
    );
};

export default Auth;
