import React, { useState, useEffect } from "react";
import ToggleButton from 'react-toggle-button';
import "./EnterValue.css";
import Axios from "axios";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import { firebaseApp } from "../../firebase";
import Cards from "../../Cards/Cards";
const EnterValue = props => {
  const [dollarPerCoin, setDollarPerCoin] = useState();
  const [wait, setWait] = useState(false);
  const [takeOutPercent, setTakenOutPercent] = useState();
  const [mainHeading, setMainHeading] = useState();
  const [showCards, setShowCards] = useState();
  useEffect(() => {
    setWait(true);
    firebaseApp
      .firestore()
      .collection("calculator")
      .onSnapshot(snapshot => {
        setDollarPerCoin(snapshot.docs[0].data().oneDollarPerCoin);
        setTakenOutPercent(snapshot.docs[0].data().takenOutPercent);
        setMainHeading(snapshot.docs[0].data().mainHeading);
        setShowCards(snapshot.docs[0].data().showCard);
        setWait(false);

      });

  }, []);
  const UpdateRecord = () => {
    setWait(true);
    firebaseApp
      .firestore()
      .collection("calculator")
      .doc("uYmfy4wVzTu5Auhbrifj")
      .update({
        oneDollarPerCoin: dollarPerCoin,
        takenOutPercent: takeOutPercent,
        showCard:showCards,
        mainHeading:mainHeading
      })
      .then(res => {
        setWait(false);
        Swal.fire({
          title: "Success!",
          text: "Values Update Successfully",
          icon: "success",
          confirmButtonText: "OK"
        });
      })
      .catch(error => {
        setWait(false);
        Swal.fire({
          title: "Error!",
          text: "Something Went Wrong! Please Check Your Network Connection",
          icon: "error",
          confirmButtonText: "OK"
        });
      });
  };
  const Go = () => {
    props.history.push("/");
  };
  if (wait) {
    return (
      <div className="Loader">
        {" "}
        <Loader type="Oval" color="#18207e" />
      </div>
    );
  } else
    return (
      <div className="EnterValueMain">
        <br />
        <div className="EnterValueSecond">
          <h1>Update Coins Data</h1>
          <div className="EnterValuedivMain">
            <div className="EnterValueddivHeader"> Coins per 1$ </div>{" "}
            <div className="EnterValueddivValue">
              <input
                value={dollarPerCoin}
                onChange={e => setDollarPerCoin(e.target.value)}
              />
              <span>Coins</span>
            </div>

          </div>
          <div className="EnterValuedivMain">
            <div className="EnterValueddivHeader"> TakenOut %</div>{" "}
            <div className="EnterValueddivValue">
              <input
                value={takeOutPercent}
                onChange={e => setTakenOutPercent(e.target.value)}
              />{" "}
              <span>%</span>
            </div>
          </div>
          <div className="EnterValuedivMain">
            <div className="EnterValueddivHeader">Main Heading</div>{" "}
            <div className="EnterValueddivValue">
            <input
              style={{width:'100%'}}
                value={mainHeading}
                onChange={e => setMainHeading(e.target.value)}
              />
            </div>

          </div>
          <div className="EnterValuedivMain">
            <div className="EnterValueddivHeader">Show Card on Main Screen</div>{" "}
            <div className="EnterValueddivValue">
            <ToggleButton
  value={showCards}
  onToggle={(value) => {
    setShowCards(!showCards)
  }
  }
  />
            </div>

          </div>

          <div className="buttonParent">
            <button onClick={UpdateRecord}>Update</button>
            <Link to="/">
              <button>Go</button>
            </Link>
          </div>
          <br />
        </div>

        <Cards edit={true}  heading={mainHeading}/>
        <br />
      </div>
    );
};

export default EnterValue;
