import React from 'react';
import {BrowserRouter,Switch,Route} from 'react-router-dom';
import Home from './Home/Home';
import Auth from './Auth/Auth';
import EditCard from './EditCard/EditCard';
import EnterValue from './Auth/EnterValue/EnterValue';
const Router = () => {
    return (<BrowserRouter>
    <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/EditCard/:id" exact component={EditCard} />
        <Route path="/EnterValue" exact component={EnterValue} />

        <Route path="/auth" exact component={Auth} />
    </Switch>
    </BrowserRouter>  );
}

export default Router;