import React, { useState, useEffect } from "react";
import Go from "../images/arrow.svg";
import "./Home.css";
import EarningShow from "../EarningShow/EarningShow";
import Cards from "../Cards/Cards";
import { firebaseApp } from "../firebase";
const Home = () => {
  const [dollarPerCoin, setDollarPerCoin] = useState();
  const [takeOutPercent, setTakenOutPercent] = useState();
  useEffect(() => {
    firebaseApp
      .firestore()
      .collection("calculator")
      .onSnapshot(snapshot => {
        setDollarPerCoin(snapshot.docs[0].data().oneDollarPerCoin);
        setTakenOutPercent(snapshot.docs[0].data().takenOutPercent);
        enterHeading(snapshot.docs[0].data().mainHeading);
        enterShowAds(snapshot.docs[0].data().showCard);
      });
  }, []);
  const [showCard, setShowCard] = useState(false);
  const [coins, setCoins] = useState();
  const [image, setImage] = useState();
  const [dollars, enterDollars] = useState();
  const [takenOut, enterTakenOut] = useState();
  const [amountKeep, enterAmountKeep] = useState();
  const [showAds, enterShowAds] = useState();
  const [heading, enterHeading] = useState();
  const GoButton = e => {
    e.preventDefault();
    setShowCard(false);
    const d = coins / dollarPerCoin;
    enterDollars(coins / dollarPerCoin);
    const takenOutP = takeOutPercent / 100;
    const taken = d * takenOutP;

    const keep = d - taken;
    enterDollars((coins / dollarPerCoin).toFixed(2));
    enterTakenOut(taken.toFixed(2));
    enterAmountKeep(keep.toFixed(2));
    setTimeout(() => {
      setShowCard(true);
    }, 500);
  };
  return (
    <>
      <form onSubmit={GoButton}>
        <div className="HomeMain">
          <h1>Gift Points Calculator</h1>
          <br />
          <div className="HomeSecond">
            <input
              placeholder="Enter Gift Points"
              type='number'
              onChange={e => {
                setCoins(e.target.value);
                setShowCard(false);
              }}
            />
            <div className="GoButton" type="submit" onClick={GoButton}>
              <img src={Go} alt="Go Button" />
            </div>
            {/* <button>Go</button> */}
          </div>
          <p>
            Enter your Total Gift Points number up above ,<br />
            and we'll do all the math for you!
          </p>
        </div>
      </form>
      <div className="EarningShow">
        {showCard ? (
          <EarningShow
            coins={coins}
            dollarPerCoin={dollarPerCoin}
            dollars={dollars
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
            takenOut={takenOut
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
            takenOutPercentage={takeOutPercent
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
            amountKeep={amountKeep
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
          />
        ) :null}
      </div>
     {showAds? <Cards heading={heading}/>:null}
      <div className="Footer" style={!showAds?{marginTop:'30%'}:{}}>
        TikTokGift Points Calculator | Powered by Kombucha and
        TikTokMarketingSecrets.com
      </div>
    </>
  );
};

export default Home;
