import React, { useState, useRef, useEffect } from "react";
import "./EditCard.css";
import firebaseStorage from "../firebase";
import { firebaseApp } from "../firebase";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";
const EditCard = props => {
  const [wait, setWait] = useState(true);
  const [image, setImage] = useState();
  const [instantImage, setInstantImage] = useState();
  const [heading, setHeading] = useState();
  const [description, setDescription] = useState();
  const [showImage, setShowImage] = useState();
  const [link, setLink] = useState();
  useEffect(() => {
    let number = 0;
    firebaseApp
      .firestore()
      .collection("cards")
      .onSnapshot(snapshot => {
        if (props.match.params.id === "iKF4W8TL02bY62GiHJ61") {
          number = 0;
        } else if (props.match.params.id === "wly6BkqlGF9uJpmQwbwK") {
          number = 1;
        } else {
          number = 2;
        }
        setShowImage(snapshot.docs[number].data().imageUrl);
        setHeading(snapshot.docs[number].data().heading);
        setDescription(snapshot.docs[number].data().description);
        setLink(snapshot.docs[number].data().link);
      });
  }, []);

  const onImageChange = e => {
    setImage(e.target.files[0]);
    setInstantImage(URL.createObjectURL(e.target.files[0]));
  };
  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };
  const inputFile = useRef(null);

  const update = () => {
    setWait(false);
    if (image) {
      const uploadTask = firebaseStorage.ref(`images/${image.name}`).put(image);
      uploadTask.on(
        "state_changed",
        snapshot => {
          // progress function
        },
        error => {
          // error function
          console.log(error);
          setWait(true);
        },
        () => {
          firebaseStorage
            .ref("images")
            .child(image.name)
            .getDownloadURL()
            .then(url => {

              setShowImage(url);
              firebaseApp
                .firestore()
                .collection("cards")
                .doc(props.match.params.id)
                .update({
                  description: description,
                  heading: heading,
                  imageUrl: url,
                  link: link
                })
                .then(res => {
                  setWait(true);
                  Swal.fire({
                    title: "Success!",
                    text: "Values Update Successfully",
                    icon: "success",
                    confirmButtonText: "OK"
                  });
                  props.history.push("/EnterValue");
                })
                .catch(error => {
                  console.log("error is here", error);
                  setWait(true);
                  Swal.fire({
                    title: "Error!",
                    text:
                      "Something Went Wrong! Please Check Your Network Connection",
                    icon: "error",
                    confirmButtonText: "OK"
                  });
                });
            });
        }
      );
    } else {
      firebaseApp
        .firestore()
        .collection("cards")
        .doc(props.match.params.id)
        .update({
          description: description,
          heading: heading,
          imageUrl: showImage,
          link: link
        })
        .then(res => {
          setWait(true);
          Swal.fire({
            title: "Success!",
            text: "Values Update Successfully",
            icon: "success",
            confirmButtonText: "OK"
          });
          props.history.push("/EnterValue");
        })
        .catch(error => {
          console.log("error is here", error);
          setWait(true);
          Swal.fire({
            title: "Error!",
            text: "Something Went Wrong! Please Check Your Network Connection",
            icon: "error",
            confirmButtonText: "OK"
          });
        });
    }
  };

  if ((!heading && !description && !link && !showImage) || !wait) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "40px"
        }}
      >
        <Loader type="Oval" color="#18207e" />
      </div>
    );
  } else
    return (
      <div className="CardEditMain">
        <div className="CardEditSecond">
          <div className="EditCardImage">
            <img
              src={instantImage ? instantImage : showImage}
              onClick={onButtonClick}
            />
          </div>
          <input
            type="file"
            id="file"
            ref={inputFile}
            onChange={onImageChange}
            style={{ display: "none" }}
          />
          <div className="CardContent">
            <div>
              <div className="EditCardHeading">Heading</div>
              <input
                value={heading}
                onChange={e => setHeading(e.target.value)}
              />
            </div>
            <div>
              <div className="EditCardHeading">Description</div>
              <textarea
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            </div>
            <div>
              <div className="EditCardHeading">Link *</div>
              <input value={link} onChange={e => setLink(e.target.value)} />
            </div>

            <button onClick={update}>Update</button>
            <br />
          </div>
        </div>
      </div>
    );
};

export default EditCard;
