import React from "react";
import "./EarningShow.css";

const EarningShow = props => {
  const {
    coins,
    dollarPerCoin,
    dollars,
    takenOut,
    takenOutPercentage,
    amountKeep
  } = props;
  return (
    <div className="EarningShowMain">
      <div className="EarningShowSecond">
        <h1>Here are the results!</h1>
        <div className="divMain">
          <div className="divHeader">$1</div>{" "}
          <div className="divValue">{dollarPerCoin} Coins</div>
        </div>
        <div className="divMain">
          <div className="divHeader">Total Gift Points </div>{" "}
          <div className="divValue">{coins} Coins</div>
        </div>
        <div className="divMain">
          <div className="divHeader">Total Amount Made</div>{" "}
          <div className="divValue">${dollars}</div>
        </div>
        <div className="divMain">
          <div className="divHeader" style={{ color: "red" }}>
            TikTok Keeps<span>({takenOutPercentage}%)</span>
          </div>{" "}
          <div className="divValue">- ${takenOut}</div>
        </div>
        <div className="divMain" style={{ backgroundColor: "#DCDCDC" }}>
          <div className="divHeader" style={{ color: "green" }}>
            Influencer Keeps
          </div>{" "}
          <div className="divValue">${amountKeep}</div>
        </div>
        <br />
      </div>{" "}
    </div>
  );
};

export default EarningShow;
