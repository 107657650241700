import React from "react";
import "./OneCard.css";
import { Link } from "react-router-dom";
const OneCard = props => {
  return (
    <div className="CardMain">
      <div className="CardImage">
      <a href={props.data.link} target="_blank">
        <img src={props.data.imageUrl} />
        </a>
      </div>
      <div className="CardContent">
        <h3>{props.data.heading}</h3>

        <p>{props.data.description}</p>
        {props.edit ? (
          <Link to={`/EditCard/${props.id}`}>
            <button>Edit</button>
          </Link>
        ) : (
          <a href={props.data.link} target="_blank">
            <button>View site</button>
          </a>
        )}
        <br />
      </div>
    </div>
  );
};

export default OneCard;
