import React, { useEffect, useState } from "react";
import "./Card.css";

import OneCard from "./OneCard/OneCard";
import { firebaseApp } from "../firebase";
import Loader from "react-loader-spinner";
const Cards = props => {
  const [card1, setCard1] = useState();
  const [card2, setCard2] = useState();
  const [card3, setCard3] = useState();
  useEffect(() => {
    firebaseApp
      .firestore()
      .collection("cards")
      .onSnapshot(snapshot => {
        setCard1(snapshot.docs[0].data());
        setCard2(snapshot.docs[1].data());
        setCard3(snapshot.docs[2].data());
      });
  }, []);
  return (
    <>
      <div className="CardsMain">
  <h1>{props.heading}</h1>
        {card1 && card2 && card3 ? (
          <div className="OurCards">
            <div className="CardFix">
              <OneCard
                data={card1}
                edit={props.edit}
                id="iKF4W8TL02bY62GiHJ61"
                number={1}
              />
              <OneCard
                data={card2}
                edit={props.edit}
                id="wly6BkqlGF9uJpmQwbwK"
                number={2}
              />
            </div>
            <OneCard
              data={card3}
              edit={props.edit}
              id="zGFpPfrqt72ulI892ZLo"
              number={3}
            />
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "40px"
            }}
          >
            <Loader type="Oval" color="#18207e" />
          </div>
        )}
      </div>
    </>
  );
};

export default Cards;
